.fields {
  position: relative;
  margin-top: ac(128px, 50px, 768, 1440);

  &__decor {
    position: absolute;
    top: ac(425px, 240px, 768, 1440);
    left: 0;
    width: ac(460px, 200px, 768, 1440);
    height: ac(140px, 61px, 768, 1440);

    img {
      object-fit: contain;
    }
  }

  &__wrap {
    display: flex;
  }

  h2 {
    margin-left: -2px;
  }

  &__img {
    flex: 0 0 auto;
    margin-right: ac(120px, 30px, 768, 1440);
    margin-top: 14px;
    width: ac(480px, 250px, 768, 1440);
    height: ac(480px, 250px, 768, 1440);
    border-radius: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);

    img {
      border-radius: 25px;
    }
  }

  &__subtitle {
    margin-top: ac(22px, 19px);
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);
    font-weight: 500;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: ac(42px, 28px);
  }

  &__col {
    width: calc(50% - ac(14px, 10px, 768, 1440));
  }

  &__item {
    display: flex;
    padding: ac(22px, 12px) 0 ac(19px, 9px);
    border-top: 1px solid var(--gainsboro);
    font-size: 16px;
    line-height: 26px;

    &::before {
      content: '';
      flex: 0 0 auto;
      width: ac(16px, 12px);
      height: ac(16px, 12px);
      margin-top: ac(5px, 7px);
      margin-right: ac(15px, 10px);
      border-radius: 50%;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.051' height='19.052' viewBox='0 0 19.051 19.052'%3E%3Cg transform='translate(-56.464)'%3E%3Cpath d='M391.267,96.631q.228.011.454.011a9.525,9.525,0,0,0,9.52-9.525,1.176,1.176,0,1,0-2.353,0,7.17,7.17,0,1,1-3.655-6.253,1.176,1.176,0,1,0,1.155-2.05,9.526,9.526,0,1,0-5.122,17.817Z' transform='translate(-325.726 -77.59)' fill='%2325c4ca'/%3E%3Cpath d='M417.238,104.116a1.176,1.176,0,1,0-1.664,1.664l3.282,3.283a1.8,1.8,0,0,0,2.539,0l7.218-7.218a1.176,1.176,0,1,0-1.664-1.664L420.126,107Z' transform='translate(-353.917 -96.572)' fill='%2325c4ca'/%3E%3C/g%3E%3C/svg%3E") center / contain no-repeat;
    }

    &:last-child {
      border-bottom: 1px solid var(--gainsboro);
    }
  }

  &__btn {
    margin-top: ac(39px, 31px);
    min-width: 152px;
  }

  @mixin full {
    margin-top: 145px;

    &__decor {
      top: 515px;
      width: 550px;
      height: 170px;
    }

    &__img {
      width: 600px;
      height: 600px;
      margin-right: 200px;
    }

    &__list {
      margin-top: 50px;
    }

    &__col {
      width: calc(50% - 20px);
    }

    &__subtitle {
      margin-top: 25px;
      font-size: 24px;
      line-height: 36px;
    }

    &__item {
      padding: 24px 0 21px;
      font-size: 18px;
      line-height: 28px;

      &::before {
        width: 18px;
        height: 18px;
        margin-top: 6px;
        margin-right: 20px;
      }
    }

    &__btn {
      margin-top: 45px;
      min-width: 185px;
    }
  }

  @mixin sm {
    margin-top: -76px;

    &__decor {
      top: 300px;
      width: 300px;
      height: 91px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__img {
      width: 100%;
      height: 345px;
      margin-top: 0;
      margin-right: 0;

      img {
        object-position: top;
      }
    }

    &__text {
      margin-top: 145px;
    }
  }

  @mixin xs {
    h2 {
      max-width: 10ch;
    }

    &__img {
      &,
      img {
        border-radius: 15px;
      }
    }

    &__list {
      flex-direction: column;
    }

    &__col {
      width: 100%;

      &:first-child {
        .fields__item {
          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
