.footer {
  padding: ac(80px, 75px) 0 ac(80px, 31px);

  &__wrap {
    display: flex;
  }

  .js-dropdown {
    position: relative;
    z-index: 2;

    &:hover {
      .footer__link {
        color: var(--golden);
      }
    }

    .footer__link {
      transition: .3s ease;

      &:hover {
        cursor: pointer;
      }
    }

    .header__dropdown {
      width: calc(100% + ac(160px, 120px));
      background-color: #fff;

      .header__link {
        font-size: ac(19px, 15px);
      }
    }

    .header__link {
      opacity: 1;
      transform: unset;
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 13px;
      }
    }
  }

  &__logo {
    position: relative;
    margin-right: 46px;
    flex: 0 0 auto;
    min-width: ac(144px, 88px);
    min-height: ac(120px, 73px);
    max-width: ac(144px, 88px);
    max-height: ac(120px, 73px);

    img {
      position: absolute;
      object-fit: contain;
      transition: 0.3s ease;

      &:nth-child(2) {
        opacity: 0;
      }
    }

    &:hover {
      img {
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }

  &__col {
    padding-top: ac(42px, 20px, 568, 1440);
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    border-top: 1px solid var(--gainsboro);
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    margin-right: ac(30px, 15px);

    .footer__link {
      font-size: ac(18px, 15px);
      line-height: ac(26px, 25px);
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__socials {
    display: flex;
    margin-right:  ac(-20px, -10px);
  }

  &__contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: ac(20px, 10px);
    margin-bottom: ac(25px, 15px);
    font-size: 20px;
    line-height: ac(26px, 25px);
    color: var(--white);
    background-color: var(--macaw);
    transition: .3s ease;

    &.icon-mail {
      font-size: 18px;
    }

    &:hover {
      background-color: var(--golden);
      color: var(--white);
    }
  }

  &__right {
    flex-direction: column;
    align-items: flex-end;
    display: flex;
  }

  &__nav,
  &__terms {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  &__nav {
    margin-right: ac(-39px, -30px, 568, 1440);
    margin-bottom: -10px;

    & > * {
      margin-right: ac(39px, 30px, 568, 1440);
      margin-bottom: 10px;
      font-size: ac(18px, 15px);
      line-height: ac(26px, 25px);
      font-weight: 500;
    }
  }

  &__terms {
    margin-top: ac(25px, 15px, 568, 1440);
    margin-right: -40px;

    & > * {
      margin-right: 40px;
    }

    &--alt {
      & > * {
        font-size: 14px;
      }
    }
  }

  &__bottom {
    width: 100%;
    justify-content: space-between;
    display: flex;
    margin-top: ac(35px, 25px);

    span {
      width: 200px;
    }
  }

  @mixin full {
    padding: 100px 0;

    &__logo {
      margin-right: 100px;
      width: 170px;
      height: 142px;
    }

    &__col {
      padding-top: 60px;
    }

    &__contacts {
      .footer__link {
        font-size: 20px;
        line-height: 28px;
        margin-right: 65px;
      }
    }

    &__contact {
      width: 45px;
      height: 45px;
      margin-right: 35px;
      font-size: 22px;

      &.icon-mail {
        font-size: 20px;
      }
    }

    &__nav {
      margin-right: -65px;

      & > * {
        margin-right: 65px;
        font-size: 20px;
        line-height: 28px;
      }
    }

    &__terms {
      margin-top: 30px;
      margin-right: -65px;

      & > * {
        margin-right: 65px;
      }
    }
  }

  @mixin sm {
    &__col {
      flex-direction: column;
    }

    &__socials {
      justify-content: flex-end;
    }

    &__contacts {
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 20px;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;

      .footer__link {
        margin-left: 25px;
        text-align: right;
      }
    }
  }

  @mixin xs {
    &__bottom {
      flex-direction: column;
      align-items: center;
    }

    &__wrap {
      flex-direction: column;
      align-items: center;
    }

    &__contact {
      font-size: 18px;

      &.icon-mail {
        font-size: 16px;
      }
    }

    &__contacts {
      margin-right:  auto;
      flex-direction: column;

      .footer__link {
        text-align: center;
        margin-left: 0;
      }
    }

    &__right {
      align-items: center;
    }

    &__logo {
      position: relative;
      margin-right: 0;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 50%;
        width: 500px;
        height: 1px;
        background-color: var(--gainsboro);
      }

      &::before {
        left: -35px;
        transform: translate(-100%, -50%);
      }

      &::after {
        right: -35px;
        transform: translate(100%, -50%);
      }
    }

    &__col {
      padding-top: 35px;
      border-top: 0;
      width: 100%;
      align-items: center;
    }

    &__nav,
    &__terms {
      justify-content: center;
    }

    &__terms {
      margin-top: 14px;
      margin-bottom: -10px;
      margin-right: 0;
      z-index: 1;

      & > * {
        margin: 0 20px 10px;
        font-size: 14px;
        line-height: 25px;
      }
    }

    &__terms--alt {
      & > * {
        font-size: 12px;
      }
    }
  }

  @mixin xxs {
    &__terms > * {
      margin: 0 15px 10px;
    }
  }
}
