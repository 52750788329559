.header {
  position: relative;
  z-index: 100;
  background-color: var(--macaw);

  &.dark {
    background-color: var(--white);

    .header__link {
      color: var(--black);

      &:hover {
        color: var(--golden);
      }

      &.active {
        opacity: 0.35;

        &:hover {
          color: var(--black);
        }
      }
    }

    .header__dropdown {
      border-color: var(--black);
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    transition: 0.3s 0.5s ease;
  }

  &__logo {
    position: relative;
    flex: 0 0 auto;
    width: 144px;
    height: 52px;

    &:hover {
      img {
        &:nth-child(1) {
          opacity: 0;
        }
        &:nth-child(2) {
          opacity: 1;
        }
      }
    }

    img {
      object-fit: contain;
      transition: 0.3s;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

  &__navbar-box {
    width: 100%;
  }

  &__navbar {
    transition: 0.3s 0.5s ease;
  }

  &__link {
    margin-left: 20px;
    color: var(--golden);
    line-height: 1em;
    font-weight: 700;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background-color: var(--golden);
      transition: .3s;
      pointer-events: none;
    }

    &:hover {
      color: var(--white);

      &::before {
        transform: scale(0);
      }
    }

    &.active {
      opacity: 0.35;

      &:hover {
        color: var(--white);
      }
    }

    &--btn {
      min-width: ac(155px, 100px, 1025, 1440);
      height: 50px;
      background-color: var(--golden);
      border-color: var(--golden);
      color: var(--white);

      &::before {
        display: none;
      }

      &:hover {
        background-color: var(--white);
        border-color: var(--black);
        color: var(--black);
      }
    }
  }

  .js-dropdown+.btn {
    margin-left: 15px;
  }

  &__burger {
    display: none;
  }

  &__layout {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }

  .js-dropdown {
    position: relative;
    z-index: 2;

    .header__link--btn {
      margin-left: 10px;
    }

    .header__link:not(.header__link--btn) {
      &+.header__dropdown {
        min-width: 100%;
        width: 205px;
        right: 50%;
        transform: translateX(calc(50% + 20px)) translateY(100%);
      }
    }
  }

  &__dropdown {
    width: calc(100% + 100px);
    position: absolute;
    right: 0px;
    bottom: -2px;
    transform: translateY(100%);
    transform-origin: 0 0;
    border: 2px solid var(--black);
    overflow: hidden;
    visibility: hidden;
    background-color: var(--white);
    transition: .5s ease-in-out;
    max-height: 0px;

    &.open {
      max-height: 200px;
      opacity: 1;
      visibility: visible;
      overflow: hidden;
    }

    &>div {
      padding: 15px 10px 10px;
      max-height: 200px;
      overflow: auto;
      width: 100%;

      .simplebar-track.simplebar-vertical {
        width: 6px;
      }

      .simplebar-scrollbar {

        &::before {
          opacity: 1;
          background: var(--golden);
        }
      }
    }

    .header__link {
      width: 100%;
      margin-left: 0;
      line-height: 1.5em;

      &:before {
        display: none;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:hover {
        color: var(--black);
      }
    }
  }

  @media (min-width: 1025px) {
    .simplebar-content-wrapper, .simplebar-mask {
      overflow: visible !important;
    }

    .simplebar-track {
      display: none !important;
    }

    .simplebar-content {
      &>div, & {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .header__dropdown {

      .simplebar-content-wrapper, .simplebar-mask {
        overflow: auto !important;
      }

      .simplebar-content {
        &, &>div {
          flex-direction: column;
        }
        &>div {
          width: 100%;
        }

      }

      .simplebar-track {
        display: block !important;
      }
    }
  }

  @mixin full {
    &__dropdown {
      width: calc(100% + 130px);
    }

    &__wrap {
      padding: 40px 0;
    }

    &__logo {
      height: 62px;
      width: 172px;
    }

    &__link {
      margin-left: 50px;
    }
  }

  @mixin media 1200 {
    &.open {
      .header__burger {
        span {
          &:first-child {
            transform: translateY(8px) rotate(45deg);
          }

          &:last-child {
            transform: translateY(-8px) rotate(-45deg);
          }

          &:nth-child(2) {
            width: 0%;
          }
        }
      }

      .header__layout {
        visibility: visible;
        opacity: 1;
      }

      .header__navbar-box {
        width: 100vw;
        transform: scale(1);
        box-shadow: -15px 25px 35px rgba(22, 21, 21, 0.25);
      }

      .header__navbar {
        visibility: visible;
      }

      .header__link {
        opacity: 1;
        transform: translateX(0%);
        transition: 0.3s 0.2s ease;
        font-size: 16px;

        &.active {
          opacity: 0.35;
        }
      }

      &.dark {

        .header__dropdown {
          border-color: var(--white);
        }

        .header__link {
          color: var(--golden);

          &:hover {
            color: var(--white);
          }
        }

        .header__link--btn {
          color: var(--white);

          &:hover {
            color: var(--golden);
          }
        }
      }
    }

    &.dark {
      .header__burger {
        background: var(--black);
        border-radius: 0 0 0 8px;
      }
    }

    &__wrap {
      position: relative;
      padding: 20px 0;
    }

    &__logo {
      width: 120px;
      height: 44px;
    }

    &__burger {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 75px;
      height: 75px;
      margin-top: -20px;
      margin-right: ac(-35px, -15px, 768, 1440);
      z-index: 10;

      span {
        width: 35px;
        height: 3px;
        background-color: var(--white);
        transition: 0.3s ease;
      }

      &:hover {
        cursor: pointer;

        span {
          background-color: var(--golden);
        }
      }
    }

    &__navbar-box {
      position: absolute;
      top: 0;
      right: ac(-35px, -15px, 768, 1440);
      margin-top: 0;
      width: 75px;
      max-width: 300px;
      background-color: var(--black);
      overflow: hidden;
      border-radius: 0 0 0 8px;
      transform: scale(0);
      transform-origin: 100% 0;
      transition: 0.5s ease;
    }

    .simplebar-content {
      &, &>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &>div {
        width: 100%;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 8px;
    }

    .simplebar-track.simplebar-horizontal {
      display: none;
    }

    .simplebar-scrollbar {
      &::before {
        background: var(--white);
        border-radius: 0;
        opacity: 1;
      }
    }

    .js-dropdown:not(.footer) {
      width: 100%;

      .header__link--btn {
        margin-top: 10px;
        margin-left: 0px;
      }

      &:not(:first-child) {
        .header__link:not(.header__link--btn) {
          margin-top: 17px;
        }
      }

      .header__link:not(.header__link--btn) {
        &+.header__dropdown {
          bottom: -10px;
          right: 0;
          transform: translateY(0%);
        }
      }

      .header__dropdown {
        position: relative;
        transform: none;
        width: 100%;
        left: 0;
        bottom: 2px;
        background: var(--black);

        border-color: var(--white);
        &>div {
          .simplebar-scrollbar {
            &::before {
              background: var(--white);
            }
          }
        }

        .header__link {
          margin-top: 0 !important;
          color: var(--white);

          &:hover {
            color: var(--golden);
          }
        }
      }
    }

    &__navbar {
      max-height: 100vh;
      flex-direction: column;
      align-items: flex-start;
      padding: 84px 55px 67px;
      visibility: hidden;
      transition: unset;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &__link {
      font-size: 19px;
      margin-left: 0;
      opacity: 0;
      transform: translateX(100%);

      &::before {
        display: none;
      }

      &:not(:first-child) {
        margin-top: 17px;
      }
    }

    &__link--btn.header__link {
      min-width: 100%;
      margin-top: 23px;
    }

    .js-dropdown+.btn {
      margin-left: 0px;
      margin-top: 10px;
    }

  }
}
