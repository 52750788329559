.first {
  padding: ac(66px, 54px) 0 ac(16px, 29px);
  background-color: var(--macaw);

  &__wrap {
    display: flex;
    transition: .5s ease;

    & > * {
      color: var(--white);
    }
  }

  &__title {
    flex: 0 0 auto;
    max-width: 11ch;
    margin-right: ac(87px, 5px, 1024, 1440);
    transition: .5s ease;

    span {
      color: var(--black-o);
      display: inline-block;
      position: relative;

      &:nth-child(1) {
        &::before,
        &::after {
          animation-delay: 0.3s;
        }
      }

      &:nth-child(2) {
        &::before,
        &::after {
          animation-delay: 0.4s;
        }
      }

      &:nth-child(3) {
        &::before,
        &::after {
          animation-delay: 0.5s;
        }
      }

      &:nth-child(4) {
        &::before,
        &::after {
          animation-delay: 0.6s;
        }
      }
      &:nth-child(5) {
        &::before,
        &::after {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(6) {
        &::before,
        &::after {
          animation-delay: 0.8s;
        }
      }
      &:nth-child(7) {
        &::before,
        &::after {
          animation-delay: 0.9s;
        }
      }
      &:nth-child(8) {
        &::before,
        &::after {
          animation-delay: 1s;
        }
      }

      &::before {
        animation: max-height 0.7s cubic-bezier(0.61, 1, 0.88, 1) 1 normal both;
        color: var(--golden);
      }

      &::after {
        animation: max-width 1.2s cubic-bezier(0.61, 1, 0.88, 1) 1 normal both;
        color: var(--white);
      }

      &::before,
      &::after {
        content: attr(data-text);
        left: 0;
        overflow: hidden;
        position: absolute;
        speak: none;
      }
    }
  }

  @keyframes max-width {
    from {
      max-width: 0;
    }

    to {
      max-width: 100%;
    }
  }

  @keyframes max-height {
    from {
      max-height: 0;
    }

    to {
      max-height: 100%;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__text {
    max-width: 25ch;
    margin-top: ac(2px, 0px, 768, 1440);
    font-size: ac(46px, 36px);
    font-weight: 800;
    line-height: 1em;
  }

  &__subtitle {
    min-height: 130px;
    margin-top: ac(25px, 15px);
    max-width: 46ch;
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);
    font-weight: 500;
    transition: .5s ease;

    .typed-cursor {
      display: inline-block;
      font-family: serif;
      font-weight: 400;
      font-size: 1.2em;
      transform: translateX(2px) scaleX(0.5);
    }
  }

  @mixin full {
    padding: 58px 0 20px;

    &__title {
      margin-right: 140px;
    }

    &__text {
      font-size: 66px;
    }

    &__subtitle {
      margin-top: 30px;
      font-size: 24px;
      line-height: 36px;
      min-height: 145px;
    }
  }

  @mixin md {
    &__title {
      margin-right: ac(87px, 20px, 768, 1440);
    }

    &__subtitle {
      min-height: 210px;
    }
  }

  @mixin sm {
    &__wrap {
      flex-direction: column;
    }

    &__col {
      margin-top:  20px;
    }

    &__subtitle {
      max-width: 100%;
      min-height: 140px;
    }
  }

  @mixin xxs {
    &__text {
      font-size: 32px;
    }

    &__subtitle {
      min-height: 190px;
    }
  }
}
