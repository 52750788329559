:root {
  --main: #707070;
  --blue: #64c2c8;
  --white: #ffffff;
  --dark-blue: #0e1127;
  --dark-gray: #343434;
  --light-gray: #dce1e4;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }
}

.popup {
  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    max-height: 85vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--light-gray) var(--dark-blue);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: var(--light-gray);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--dark-blue);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--dark-blue);
    }
    --default-translate-x-from: -50%;
    --default-translate-x-to: -50%;
    --default-translate-y-from: -50%;
    --default-translate-y-to: -50%;
    --opacity-from: 0;
    --opacity-to: 1;
    background: var(--white);
    position: absolute;
    top: 50%;
    left: 50%;
    width: ac(1000px, 300px);
    padding: 45px ac(45px, 15px) ac(45px, 15px);
    transform: translateY(var(--default-translate-y-to))
      translateX(var(--default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.apply-popup-styles {
      width: ac(900px, 300px);
    }

    &.without-opacity {
      --opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --default-translate-x-from: 0;
      --default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --default-translate-y-from: 0;
      --default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --default-translate-x-from: 0;
      --default-translate-x-to: 0;

      .popup__container {
        max-height: 90vh;
      }
    }

    @media only screen and (max-width: 1025px) {
      width: 95%;
      min-height: auto;

      &:not(.full-height) {
        max-height: 90%;
      }
    }
  }

  &__title {
    margin-bottom: min(max(30px, 3.47vw), 50px);
    font-size: ac(36px, 28px);
    line-height: ac(50px, 38px);
    font-weight: 700;
    font-family: var(--font-main);
  }

  &__btn-close {
    position: absolute;
    right: min(max(15px, 1.74vw), 25px);
    top: min(max(15px, 1.74vw), 25px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .cross-clip {
      width: 24px;
      height: 24px;
      background: var(--black);
      transition: 0.3s ease;
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    &:hover .cross-clip {
      background: var(--golden);
    }
  }
}

.old-popup-styles {
  border-radius: 8px;
  border: 2px solid var(--black);
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__head {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 100px;
    flex: 0 0 auto;
    margin-bottom: 20px;

    span {
      font-weight: 500;

      &:first-child {
        font-size: ac(20px, 18px);
        margin-bottom: ac(10px, 5px);
      }

      &:not(:first-child) {
        font-size: ac(18px, 16px);
        line-height: ac(22px, 20px);
        color: var(--macaw);
      }
    }
  }

  &__descr {
    margin-top: ac(45px, 20px);
    word-break: break-word;

    ul {
      li {
        color: var(--black);

        &::before {
          background-color: var(--black);
        }
      }
    }

    h2,
    h3,
    h4 {
      &:not(:first-child) {
        margin-top: 0.8em;
        margin-bottom: 0.4em;
      }
    }

    p,
    ul,
    ol {
      &:not(:last-child) {
        margin-bottom: 1em;
      }
    }

    a {
      display: inline-block;
      text-decoration: underline;
    }
  }

  .btn {
    margin-top: ac(45px, 30px);
    margin-left: auto;
  }
}

.pc-cv-field input[type="file"] {
  display: none;
}

.popup-apply {
  margin-top: ac(-15px, 0px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &>span {
    width: 100%;
    font-size: ac(24px, 18px);
    line-height: ac(32px, 26px);
    font-weight: 500;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__input {
    width: calc(50% - ac(20px, 10px));
    margin-bottom: ac(25px, 20px);
    padding: ac(15px, 10px) 0;
    border-bottom: 1px solid var(--gainsboro);
    border-radius: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--black) !important;
    }

    &:focus,
    &:valid {
      border-color: var(--black);
    }

    &,
    &::placeholder {
      font-family: var(--font-main);
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
    }

    &::placeholder {
      color: var(--black-o);
    }
  }

  &__upload-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: ac(20px, 15px);
  }

  &__upload-label {
    margin-bottom: 10px;
    font-size: ac(24px, 18px);
    line-height: ac(36px, 22px);
    font-weight: 600;
    color: var(--black);
  }

  &__upload-btn {
    width: calc(50% - ac(20px, 10px));
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(20px, 16px);
    border: 2px solid var(--black);
    transition: 0.3s ease;
    cursor: pointer;

    &:hover {
      color: var(--white);
      background-color: var(--black);
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -20px;

    & > * {
      margin-top: 20px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;
    position: relative;

    &__input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
      height: 0;

      &:checked + .checkbox__text {
        &::before {
          background: var(--black);
        }
      }
    }

    &__text {
      display: block;
      position: relative;
      padding-right: 20px;
      padding-left: 40px;
      font-family: var(--font-main);
      font-size: 18px;
      color: var(--black);
      text-transform: unset;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        box-sizing: border-box;
        width: 25px;
        height: 25px;
        border: ac(2px, 1px) solid var(--black);
        background: var(--white);
        transition: all 300ms ease-out;
      }

      a {
        font-weight: 600;
        color: var(--black);
        text-decoration: underline;
        transition: 0.3s ease;

        &:hover {
          color: var(--golden);
        }
      }
    }
  }
}

@mixin full {
  .popup {
    &__title {
      font-size: 44px;
      line-height: 58px;
    }
    &__block {
      width: 1200px;
      padding: 50px 75px;

      &.apply-popup-styles {
        width: 1000px;
      }
    }
  }

  .info {
    &__item {
      margin-right: 150px;

      span {
        &:first-child {
          font-size: 24px;
          margin-bottom: 15px;
        }

        &:not(:first-child) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
  }

  .popup-apply {
    &__input {
      padding: 25px 0;

      &,
      &::placeholder {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

@mixin xs {
  .info {
    .btn {
      max-width: 100%;
      min-width: unset;
    }
  }

  .popup-apply {
    &__upload-btn {
      max-width: 345px;
      width: 100%;
    }

    &__input {
      width: 100%;
    }

    .btn {
      min-width: unset;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media only screen and (max-width: 550px) {
  .popup-center {
    .close-popup {
      font-size: 20px;
      top: 20px;
      right: 15px;
    }
  }
}

@media only screen and (max-width: 420px) {
  .popup {
    &.popup_height_full {
      padding: 40px 20px;
    }
  }
}

@mixin full {
  .popup {
    &__title {
      font-size: 20px;
      line-height: 30px;
    }
  }
}