.features {
  position: relative;
  margin-top: ac(-230px, -300px);
  padding: ac(354px, 381px) 0 ac(339px, 75px);
  background-color: var(--seashell);

  &__decor {
    position: absolute;
    bottom: 0;
    left: 0;
    width: ac(799px, 280px);
    height: ac(799px, 280px);

    img {
      object-fit: contain;
    }
  }

  &__wrap {
    position: relative;
    display: flex;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ac(30px, 10px, 768, 1440) ac(40px, 15px, 768, 1440);
    border: 3px solid var(--black);
    background-color: var(--white);
    border-radius: 8px;
    perspective: 1000px;
    transform-style: preserve-3d;

    &:hover {
      .features__icon {
        transform: translateZ(50px);
      }

      .features__title, .features__subtitle {
        transform: translateZ(40px);
      }
    }

    &:not(:last-child) {
      margin-right: ac(55px, 15px, 768, 1440);
    }

    &:nth-child(2) {
      background-color: var(--macaw);
      color: var(--white);
      border-color: var(--macaw);
    }

    &:nth-child(3) {
      background-color: var(--golden);
      border-color: var(--golden);

      .features__icon {
        img {
          width: 85%;
        }
      }
    }
  }

  &__icon {
    width: 77px;
    height: 77px;
    transition: 0.3s ease;

    img {
      object-fit: contain;
      object-position: bottom;
    }
  }

  &__title {
    margin-top: 20px;
    transition: 0.3s ease;
  }

  &__subtitle {
    margin-top: 17px;
    transition: 0.3s ease;
  }

  @mixin full {
    padding-top: 380px;
    padding-bottom: 360px;

    &__decor {
      width: 950px;
      height: 950px;
    }

    &__item {
      padding: 40px 45px;

      &:not(:last-child) {
        margin-right: 100px;
      }
    }

    &__icon {
      width: 90px;
      height: 90px;
    }
  }

  @mixin sm {
    &__wrap {
      flex-direction: column;
    }

    &__item {
      flex-flow: row wrap;
      align-items: center;
      margin-right: 20px;
      padding: ac(30px, 10px, 375, 768) ac(40px, 10px, 375, 768);

      &:not(:first-child) {
        margin-top: 35px;
      }

      &:nth-child(3) {
        .features__subtitle {
          margin-top: 21px;
        }
      }
    }

    &__title {
      margin: 0;
    }

    &__subtitle {
      width: 100%;
      margin-top: 16px;
    }

    &__icon {
      margin-right: 15px;

      img {
        object-position: center;
      }
    }
  }

  @mixin xxs {
    &__icon {
      margin-right: 10px;
    }
  }
}
