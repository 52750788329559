.muve {
  position: relative;
  margin-top: ac(17px, 15px);
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 31px);
    background-color: var(--golden);
    border-radius: 0 0 0 25px;
    z-index: -1;
  }

  &__text {
    width: calc(100% + ac(50px, 20px));
    /* margin-left: -10px; */
    margin-left: ac(200px, 30px);
  }

  &__decor {
    position: absolute;
    right: 0;
    bottom: 31px;
    width: ac(333px, 180px, 768, 1440);
    height: ac(333px, 180px, 768, 1440);
    transition: 0.3s 0.5s ease;
    opacity: 0;

    img {
      object-fit: contain;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &.anim {
      opacity: 1;
      transition: 0.3s 0.3s ease;

      .muve__line {
        transition: 0.5s ease;

        &:nth-child(1) {
          transform: translate(0.002px, 165.233px) rotate(-45deg) scale(1);
          transition-delay: 0.1s;
        }

        &:nth-child(2) {
          transform: translate(13.875px, 151.359px) rotate(-45deg) scale(1);
          transition-delay: 0.2s;
        }

        &:nth-child(3) {
          transform: translate(27.758px, 137.482px) rotate(-45deg) scale(1);
          transition-delay: 0.3s;
        }

        &:nth-child(4) {
          transform: translate(41.632px, 123.609px) rotate(-45deg) scale(1);
          transition-delay: 0.4s;
        }

        &:nth-child(5) {
          transform: translate(55.515px, 109.721px) rotate(-45deg) scale(1);
          transition-delay: 0.5s;
        }

        &:nth-child(6) {
          transform: translate(69.391px, 95.841px) rotate(-45deg) scale(1);
          transition-delay: 0.6s;
        }

        &:nth-child(7) {
          transform: translate(83.265px, 81.978px) rotate(-45deg) scale(1);
          transition-delay: 0.7s;
        }

        &:nth-child(8) {
          transform: translate(97.148px, 68.091px) rotate(-45deg) scale(1);
          transition-delay: 0.8s;
        }

        &:nth-child(9) {
          transform: translate(111.021px, 54.217px) rotate(-45deg) scale(1);
          transition-delay: 0.9s;
        }

        &:nth-child(10) {
          transform: translate(124.897px, 40.337px) rotate(-45deg) scale(1);
          transition-delay: 1s;
        }

        &:nth-child(11) {
          transform: translate(138.781px, 26.46px) rotate(-45deg) scale(1);
          transition-delay: 1.1s;
        }

        &:nth-child(12) {
          transform: translate(152.654px, 12.586px) rotate(-45deg) scale(1);
          transition-delay: 1.2s;
        }
      }
    }
  }

  &__line {
    transition: 0.5s 0s ease;

    &:nth-child(1) {
      transform: translate(0.002px, 165.233px) rotate(-45deg) scale(0);
    }

    &:nth-child(2) {
      transform: translate(13.875px, 151.359px) rotate(-45deg) scale(0);
    }

    &:nth-child(3) {
      transform: translate(27.758px, 137.482px) rotate(-45deg) scale(0);
    }

    &:nth-child(4) {
      transform: translate(41.632px, 123.609px) rotate(-45deg) scale(0);
    }

    &:nth-child(5) {
      transform: translate(55.515px, 109.721px) rotate(-45deg) scale(0);
    }

    &:nth-child(6) {
      transform: translate(69.391px, 95.841px) rotate(-45deg) scale(0);
    }

    &:nth-child(7) {
      transform: translate(83.265px, 81.978px) rotate(-45deg) scale(0);
    }

    &:nth-child(8) {
      transform: translate(97.148px, 68.091px) rotate(-45deg) scale(0);
    }

    &:nth-child(9) {
      transform: translate(111.021px, 54.217px) rotate(-45deg) scale(0);
    }

    &:nth-child(10) {
      transform: translate(124.897px, 40.337px) rotate(-45deg) scale(0);
    }

    &:nth-child(11) {
      transform: translate(138.781px, 26.46px) rotate(-45deg) scale(0);
    }

    &:nth-child(12) {
      transform: translate(152.654px, 12.586px) rotate(-45deg) scale(0);
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    margin-top: ac(63px, 30px);
  }

  &__img {
    flex: 0 0 auto;
    margin-right: ac(96px, 35px, 768, 1440);
    margin-top: 8px;
    width: ac(480px, 290px, 768, 1440);
    height: 239px;
    border-radius: 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);

    img {
      border-radius: 25px;
    }
  }

  &__subtitle {
    margin-top: ac(20px, 13px);
    max-width: 30ch;
    font-size: ac(22px, 17px);
    line-height: ac(32px, 27px);
    font-weight: 500;
  }

  &__btn-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: ac(44px, 29px);
    margin-right: -20px;
    margin-bottom: -10px;
  }

  &__btn {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin full {
    &::before {
      height: calc(100% - 60px);
    }

    &__text {
      margin-left: 300px;
    }

    &__decor {
      bottom: 60px;
      width: 400px;
      height: 400px;
    }

    &__wrap {
      margin-top: 75px;
    }

    &__img {
      height: 290px;
      width: 600px;
      margin-right: 190px;
    }

    &__subtitle {
      font-size: 24px;
      line-height: 36px;
    }
  }

  @mixin sm {
    &::before {
      height: calc(100% - 28px);
      border-radius: 0 0 0 15px;
    }

    &__decor {
      bottom: 28px;
      width: ac(333px, 180px, 375, 768);
      height: ac(333px, 179px, 375, 768);
    }

    &__wrap {
      flex-direction: column;
      padding-bottom: ac(60px, 130px, 375, 768);
    }

    &__img {
      width: 100%;
      height: ac(300px, 200px, 375, 768);
      margin-top: 0;
      border-radius: 15px;

      img {
        object-position: top;
        border-radius: 15px;
      }
    }

    &__col {
      margin-top: 35px;
    }
  }

  @mixin xs {

    &__text {
      margin-left: 25px;
      width: calc(100% - 50px);
    }

    &__img {
      img {
        object-position: center;
      }
    }
  }
}
