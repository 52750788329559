.jobs {
  position: relative;
  margin-top: ac(150px, 93px);
  z-index: 2;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__slider-box {
    margin-top: ac(31px, 24px);
    width: 100%;
    display: flex;
  }

  &__slider {
    width: 100% !important;
    overflow: visible !important;
  }

  &__slide {
    max-width: ac(500px, 320px);
    height: 100%;
    padding-bottom: 33px;

    &:nth-child(3n + 1) {
      .jobs__card {
        background-color: var(--golden);
        border-color: var(--golden);
      }
    }

    &:nth-child(3n + 2) {
      .jobs__card {
        background-color: var(--macaw);
        border-color: var(--macaw);
        color: var(--white);

        &:hover {
          color: var(--black);
        }
      }
    }

    &:nth-child(3n + 3) {
      .jobs__card {
        background-color: var(--white);

        &:hover {
          color: var(--macaw);
        }
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    padding: ac(42px, 25px) ac(53px, 27px) 0;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    border: 3px solid var(--black);

    .btn {
      margin-bottom: -33px;
      margin-top: auto;
      margin-left: auto;
    }

    &:hover {
      color: var(--white);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: -4px;

    p {
      margin-top: 4px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-right: 20px;
        font-weight: 500;
      }

      &:not(:first-child) {
        font-size: 14px;
      }

      span {
        margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  &__position {
    margin-top: 27px;
    min-height: ac(100px, 76px);
  }

  &__info {
    margin-top: ac(38px, 10px);
    display: flex;

    p {
      font-weight: 500;

      &:first-child {
        margin-right: ac(50px, 40px);
      }
    }
  }

  &__descr {
    margin-top: ac(24px, 14px);
    margin-bottom: ac(46px, 27px);
  }

  @mixin full {
    margin-top: 170px;

    &__slide {
      max-width: 600px;
    }

    &__card {
      padding: 62px 73px 0;
    }

    &__head {
      p {
        span {
          font-size: 20px;
          margin-right: 15px;
        }

        &:not(:first-child) {
          font-size: 16px;
        }
      }
    }

    &__position {
      margin-top: 32px;
      min-height: 116px;
    }

    &__info {
      margin-top: 42px;

      p {
        &:first-child {
          margin-right: 65px;
        }
      }
    }

    &__descr {
      margin-top: 28px;
      margin-bottom: 50px;
    }
  }

  @mixin sm {
    .arrow {
      margin-top: 37px;
      order: 3;
    }
  }

  @mixin xs {
    &__title {
      width: 100%;
    }

    &__head {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
