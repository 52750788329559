.register {
  margin-top: ac(137px, 77px);

  &__wrap {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--golden);
    padding: ac(134px, 73px) ac(80px, 15px) ac(16px, 17px) ac(68px, 15px);
    border-radius: 25px 25px 0 0;
  }

  &__title {
    max-width: ac(492px, 351px, 768, 1440);
    flex: 0 0 auto;
    font-size: ac(66px, 36px);
    line-height: 1em;
    margin-right: 20px;
    color: var(--white);
  }

  &__btn-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: -20px;
    margin-top: -13px;
  }

  &__btn {
    margin-right: 20px;
    margin-top: 13px;
    border-color: var(--macaw);
    background: var(--macaw);

    &:hover {
      color: var(--macaw);
    }
  }

  &__decor {
    width: 100%;
    height: 234px;
    margin-top: ac(-18px, -140px);
  }

  @mixin full {
    margin-top: 150px;

    &__wrap {
      padding: 150px 100px 20px;
    }

    &__title {
      max-width: 650px;
      font-size: 86px;
      line-height: 1em;
    }

    &__decor {
      height: 250px;
    }
  }

  @mixin sm {
    &__title {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 27px;
    }

    &__wrap {
      flex-direction: column;
    }

    &__btn-box {
      justify-content: center;
      z-index: 1;
    }
  }

  @mixin xs {
    &__wrap {
      border-radius: 15px 15px 0 0;
    }
  }

  @mixin xxs {
    &__title {
      font-size: 34px;
      line-height: 1em;
    }
  }
}
