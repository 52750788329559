.way {
  position: relative;
  padding: ac(450px, 348px) 0 277px;

  border-radius: 25px 25px 0 0;

  & > * {
    color: var(--white);
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - ac(205px, 190px));
    background-color: var(--macaw);
    z-index: -1;
  }

  &__decor {
    position: absolute;
    right: ac(-40px, 0px);
    top: ac(0px, 173px);
    width: ac(678px, 195px);
    height: ac(678px, 195px);

    &--bottom {
      top: unset;
      bottom: ac(3px, -33px);
      right: 0;
      width: 100%;
      height: ac(234px, 234px);
    }
  }

  &__wrap {
    position: relative;
    display: flex;
  }

  &__title-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 auto;
    max-width: ac(410px, 375px, 1024, 1440);
    margin-right: ac(110px, 25px, 1024, 1440);

    h2 {
      margin-bottom: 20px;
    }
  }

  &__logo {
    width: ac(220px, 180px, 1024, 1440);
    height: ac(184px, 144px, 1024, 1440);

    img {
      object-fit: contain;
    }
  }

  &__col {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__card {
    width: calc(50% - ac(20px, 5px, 1024, 1440));
    padding: ac(47px, 20px, 1024, 1440) ac(51px, 20px, 1024, 1440)
      ac(49px, 20px, 1024, 1440);
    border: 3px solid var(--white);
    background-color: var(--macaw);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    transition: 0.3s ease;

    &:hover {
      background-color: var(--black);
      color: var(--macaw);

      ul {
        li {
          color: var(--macaw);

          &::before {
            background-color: var(--macaw);
          }
        }
      }

      .way__icon {
        img {
          &:nth-child(2) {
            opacity: 1;
          }
        }
      }
    }
  }

  &__icon {
    position: relative;

    transition: 0.3s ease;

    img {
      position: absolute;
      object-fit: contain;
      transition: 0.3s ease;

      &:nth-child(2) {
        opacity: 0;
      }
    }

    &--bye {
      width: 74px;
      height: 99px;
    }

    &--hello {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    margin-top: ac(44px, 25px, 1024, 1440);
    font-size: ac(26px, 19px);
    line-height: ac(38px, 29px);
    font-weight: 600;
  }

  @mixin full {
    padding-top: 540px;
    padding-bottom: 300px;

    &__title-box {
      margin-right: 200px;
      max-width: 499px;
    }

    &__logo {
      width: 250px;
      height: 214px;
    }

    &__card {
      width: calc(50% - 30px);
      padding: 55px 65px;
    }

    &__icon--bye {
      width: 89px;
      height: 114px;
    }

    &__icon--hello {
      width: 115px;
      height: 115px;
    }

    &__title {
      margin-top: 50px;
      font-size: 28px;
      line-height: 42px;
    }
  }

  @mixin md {
    &__wrap {
      flex-direction: column;
    }

    &__title-box {
      max-width: 100%;
      flex-direction: row;
      margin-right: 0;
    }

    &__logo {
      flex: 0 0 auto;
      height: 124px;
      width: 140px;
    }

    &__card {
      width: calc(50% - ac(15px, 7px, 768, 1024));
      padding: ac(40px, 15px, 568, 1024) ac(50px, 20px, 568, 1024);
    }
  }

  @mixin xs {
    &__col {
      flex-direction: column;
      margin-top: 0;
    }

    &__title-box {
      h2 {
        margin-bottom: 35px;
      }
    }

    &__card {
      width: 100%;
      display: flex;
      padding: 38px 36px 35px;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    &__icon {
      flex: 0 0 auto;
    }

    .way__icon--bye {
      width: 61px;
      height: 81px;
      margin-right: 21px;
    }

    .way__icon--hello {
      height: 81px;
      width: 81px;
      margin-right: 13px;
    }

    &__logo {
      display: none;
    }
  }
}
